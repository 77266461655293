// @import "~antd/dist/antd.css";
@import 'antd/dist/antd.variable.min.css';
@import-normalize;
@import "./common/css/helper.scss";


body {
  font-family: $font-hei;
  font-size: 14px;
  line-height: 1.2;
  background: #f5f5f5;
}

#root {
  background-color: #ffffff;
  // min-width: 1200px;
  word-break: break-all;
  height: 100%;
}

// input type=number 去掉上下箭头
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

.ant-modal-title {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #24243a !important;
}

.delete.ant-btn-text {
  color: #f71519;
}

.delete.ant-btn-text:hover {
  color: #f71519 !important;
  background: #ffeeee !important;
  border-radius: 100px !important;
}

.ant-checkbox-indeterminate {
  .ant-checkbox-inner {

    border-color: #1890ff !important;
  }
}

.ant-input {
  &::placeholder {
    color: #9ba2ab
  }
}

.ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #f3f9ff !important;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
  height: 32px;
}

.tree_flex_box {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .tree_right_model {
    color: #2558e9;
    background-color: #f3f7ff;
    display: inline-block;
    border-radius: 24px;
    max-width: 150px;
    padding: 0 10px;
    height: 25px;

    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .tree_none_model {
    color: #999999;
    display: inline-block;
    width: 34px;
    text-align: center;
  }
}

.ant-spin-nested-loading,
.ant-spin-container {
  height: 100%;
}

.node_tree_body .ant-tree-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 260px;
  display: inline-block;
}

// 文字超出隐藏...
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 130px;
}

.ellipsis_two {
  overflow: hidden; //溢出内容隐藏
  text-overflow: ellipsis; //文本溢出部分用省略号表示
  display: -webkit-box; //特别显示模式
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.oneLine {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.objEllipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 750px;
}

//滚动条样式
.page_scrollbar::-webkit-scrollbar {
  width: 5px;
}

.page_scrollbar::-webkit-scrollbar-thumb {
  height: 100px;
  border-radius: 20px;
  // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #e5e5e5;

  &:hover {
    background-color: #dcdcdc;
  }
}

.page_scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px #ffffff;
  background: #ffffff;
}

.page_scrollbar::-webkit-scrollbar-button {
  display: none;
}

.page_scrollbar::-webkit-scrollbar-button:vertical:end:increment,
.page_scrollbar::-webkit-scrollbar-button:vertical:start:increment {
  display: block;
  height: 5px;
  background-color: transparent;
}

.page_scrollbar::-webkit-scrollbar-thumb:vertical:hover,
::-webkit-scrollbar-thumb:horizontal:hover {
  background-color: #dcdcdc;
}


// 短信验证码输入框样式
.code_box {
  position: relative;

  .get_code {
    position: absolute;
    right: 0;
    top: 0;
    width: 110px;
    height: 44px;
    padding: 10px 0px;
    text-align: center;
    z-index: 10;
    cursor: pointer;
    color: #cccccc;

    div {
      font-size: 14px;
      border-left: 1px solid #cccccc;
    }
  }

  .hightCode {
    color: #2558e9;
  }
}

.ant-modal-content {
  background-color: #ffffff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;

  .ant-modal-header {
    border-radius: 10px 10px 0 0;
  }

  .ant-modal-body {
    padding: 20px;

    .ant-modal-confirm-body-wrapper {
      width: 100%;
    }
  }

  .ant-modal-footer {
    display: flex;
    flex-direction: row-reverse;
    padding-bottom: 20px;
  }

  // .ant-modal-footer .ant-btn {
  //   border-radius: 6px;
  //   color: #2558e9;
  //   border: solid 1px #2558e9;
  // }

  // .ant-modal-footer .ant-btn-primary {
  //   color: #ffffff;
  //   background-color: #2558e9;
  // }

  .ant-modal-footer .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
    margin-right: 20px;
  }

}


.ant-tree-list-scrollbar-thumb {
  width: 5px !important;
  // height: 100px !important;
  background: #e5e5e5 !important;
  border-radius: 6px !important;
}

// 文字提示样式调整
.ant-tooltip-inner {
  min-width: 32px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #ffffff;
  color: #2558e9;
}

.ant-select-item-option-active {
  background: #EBEBEB !important;
  // font-weight: bold;
  // color: #2558e9;
}

.ant-select {
  width: 100%;
}

.ant-select .ant-select-item {
  &:hover {
    background: #f8f8f8;
  }
}

.Popoverimg {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  cursor: pointer;
}


::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}


.ant-message {
  .ant-message-notice-content {
    padding: 0px;
    color: #24243a;
    margin-top: 176px;
    border-radius: 5px;
  }

  .ant-message-custom-content {
    padding: 10px 30px;
    border-radius: 5px;
  }

  .ant-message-info {
    background-color: #F3F9FF;
    border: solid 1px #2558e9;
  }

  .ant-message-success {
    background-color: #e4ffe9;
    border: solid 1px #0cd022;
  }

  .ant-message-warning {
    background-color: #FFF2E8;
    border: solid 1px #F98E36;
  }

  .ant-message-error {
    background-color: #fff1f1;
    border: solid 1px #f71519;
  }


}

// 搜索框样式
.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
  border-radius: 0 32px 32px 0;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 32px 0 0 32px;
}

// 全局通用搜索框样式
.desearch {
  width: 240px;

  .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    background: #f7f7f7;
    padding: 0;
    margin: 0;
    height: 36px;
  }

  .ant-input {
    border-radius: 10px;
    font-size: 12px;
    padding-left: 16px !important;
    height: 34px !important;
    background: #f7f7f7;
  }

  .ant-input-search-button {
    background: #2558E9;
    height: 36px !important;
  }

  .ant-input-clear-icon {
    padding-right: 10px;
  }

  .ant-input-affix-wrapper {
    border: solid 1px #f7f7f7;
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }

    &:hover {
      box-shadow: none;
      border: solid 1px #f7f7f7;
    }
  }
}

// 鼠标光标手势
.cursor {
  cursor: pointer;
}

// 切换团队列表
.propvers {
  width: 265px;
  max-height: 340px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
  position: absolute;
  right: 212px;
  top: 101px;
  z-index: 100;
  padding: 10px 15px 10px 10px;
  overflow-y: auto;
  overflow-x: hidden;

  .name {
    width: 240px;
    height: 40px;
    line-height: 40px;
    border-radius: 100px;
    color: #24243a;
    padding-left: 19px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    img {
      width: 14px;
      height: 14px;
      margin-right: 13px;
    }

    &:hover {
      background: #f3f7ff;
      border-radius: 6px;
      color: #2558e9;
    }
  }
}

.bodyPopover {
  .ant-popover {

    left: 1110px !important;
  }

}

//自定义表格公用样式
.table_data {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 195px);
  overflow: hidden;

  .table_header,
  .table_tr {
    padding-left: 15px;
    height: 48px;
    line-height: 48px;
    display: flex;
  }

  .table_header {
    background-color: #f8f9fb;
    color: #9ba2ab;

    .ant-checkbox-wrapper {
      color: #9ba2ab;
    }
  }

  .name {
    flex: 2;
    display: flex;
    min-width: 600px;
    align-items: center;

    .icon {
      width: 20px;
      margin-right: 10px;
      height: 20px;
    }
  }

  .building_name {
    flex: 1;
    display: flex;
    align-items: center;
    overflow: hidden;

    .icon {
      width: 20px;
      margin-right: 10px;
      height: 20px;
    }

    .ant-checkbox-wrapper {
      align-items: center;
    }

    .ant-checkbox {
      top: 0;
    }
  }

  .createUser,
  .createTime {
    flex: 1;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    .wait_color {
      color: #f98e36;
    }

    .blue_color {
      color: #2558e9;
    }
  }

  .operation {
    width: 200px;
    position: relative;
    color: #91919c;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .operationHeight {
    width: 20px;
    display: flex;
    height: 20px;
    align-items: center;
    justify-content: center;

    img {
      border-radius: 50%;
    }
  }

  .table_body {
    position: relative;
    height: calc(100% - 160px);
    overflow-y: auto;

    // overflow: auto;
    // min-height: 530px;
    // height: calc(100% - 150px);
    .ant-checkbox-group {
      width: 100%;
    }

    .ant-checkbox-group:last-child {
      border-bottom: none;
    }

    .table_tr {
      cursor: pointer;
      color: #24243a;
      background-color: #ffffff;
      // box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.06);
      border-bottom: 1px solid #f0f0f0;

      &:hover,
      &:focus,
      &:active {
        background-color: #f3f9ff;
        // box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.06);
      }

      .operation_data {
        position: absolute;
        width: 140px;
        right: 0;
        top: 40px;
        background-color: #ffffff;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        margin-right: 5px;
        z-index: 10;

        .item {
          text-align: center;
          line-height: 40px !important;
          border-radius: 40px !important;
          margin: 10px 10px;
          padding: 0 14px;
          color: #24243a;

          &:hover,
          &:focus {
            background-color: #f3f7ff;
            color: #2558e9;
          }
        }

        .del {
          color: #f71519;

          &:hover,
          &:focus {
            background-color: #ffeeee;
            color: #f71519;
          }
        }
      }
    }

    // &>div:last-child {
    //   border: none;
    // }
  }
}

//自定义表格-无限滚动
.custom-table {
  position: relative;
  font-size: 13px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #24243a;

  .ct-tr {
    display: flex;
    border-bottom: 1px solid #f0f0f0;
    border-left: 1px solid #f0f0f0;

    &.head {
      font-size: 14px;
      color: #9ba2ab;
      box-sizing: border-box;
      border-top: 1px solid #f0f0f0;
      background: #f8f9fb !important;
    }

    &.select {
      background-color: #f3f9ff !important;
    }
  }

  .ct-tr:hover {
    background-color: #F9F9F9;
  }

  .ct-td {
    display: flex;
    align-items: center;
    padding: 0px 16px;
    border-right: 1px solid #f0f0f0;
    word-break: break-all;
    height: 100%;
    min-width: 45px;

    >span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }


    &.center {
      text-align: center;
      justify-content: center;
    }

  }


}

.btns {
  width: 120px;
  display: flex !important;
  flex-direction: column !important;

  .ant-btn-text:hover {
    background: #f3f7ff;
    color: #2558e9;
    border-radius: 6px;
  }

  .item {
    // line-height: 40px !important;
    // border-radius: 40px !important;
    // margin: 10px 10px;
    // padding: 0 14px;
    color: #24243a;

    &:hover,
    &:focus {
      background-color: #f3f7ff;
      color: #2558e9;
    }
  }

  .del {
    color: #f71519;

    &:hover,
    &:focus {
      background-color: #ffeeee;
      color: #f71519;
    }
  }
}

// 操作按钮
.table-operation-btns {
  div {
    width: 100px;
    padding: 6px 0px;
    text-align: center;
    color: #24243A;
    cursor: pointer;

    &:hover {
      background-color: #f3f7ff;
      border-radius: 6px;
      color: #2558E9;
    }
  }

  .edit {
    &:hover {
      background-color: #f3f7ff;
      color: #2558E9;
    }
  }

  .del {
    color: #f71519;

    &:hover {
      color: #f71519;
      background-color: #ffeeee;
    }

  }
}

// 组件库表格通用样式
.ant-table-tbody>tr>td,
.ant-table-thead>tr>th,
.ant-table tfoot>tr>td,
.ant-table tfoot>tr>th {
  padding: 12px 16px;
}

.ant-table-thead>tr>th {
  padding: 13px 16px;
  background: #f8f9fb;
  color: #9BA2AB;
}

.ant-table-thead {
  tr {
    th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      width: 0;
    }

    th {
      font-weight: 400;
      border: none;
    }
  }
}

.ant-table-wrapper {
  width: 100%;
}

// 表头去掉上边框
.table_NB_top {
  .ant-table-thead .ant-table-cell {
    border-top: none;
  }
}

// 解决表格设置scroll后缺少边框问题
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table {
  border-top: none;
}

.ant-table-thead .ant-table-cell {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: none;
}

.ant-table-cell-scrollbar {
  border: none !important;
  background-color: #ffffff !important;
}

// antd组件库通用表格容器样式（表格和分页容器）
.table_box {
  position: relative;
  height: 100%;
  margin-top: 30px;
}

// 分页按钮样式
.table_pagination {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0px;
  right: 20px;
}

.ant-pagination-options-quick-jumper input {
  height: 30px;
}

.ant-pagination-item,
.ant-pagination-total-text {
  line-height: 32px;
}

// 我的项目分页样式
.data_page {
  position: absolute;
  bottom: 20px;
  right: 20px;
}


// 表格无数据 样式
.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin: auto;

  img {
    width: 400px;
    height: 270px;
  }

  .text {
    text-align: center;
    color: #9ba2ab;
  }
}


// 删除按钮 悬浮样式
.delbtn:hover {
  color: #f71519 !important;
  border-color: #f71519 !important;

  .btn-del-icon path {
    fill: #F71519;
  }
}

.delbtn {
  color: #9ba2ab !important;
  height: 36px;
  border: solid 1px #9ba2ab !important;

  .btn-del-icon {
    vertical-align: middle;
    height: 19px;
    margin-right: 3px;
  }
}

// 按钮默认高度
.ant-btn {
  height: 36px;
}

.ant-btn-sm {
  height: 24px;
}

.delbtn.ant-btn[disabled] {
  color: #9ba2ab;
  background: #ffffff;
}

.delbtn:hover,
.delbtn:focus,
.delbtn:active {
  color: #9ba2ab;
  border-color: #9ba2ab;
  background: #fff;
  border: solid 1px #9ba2ab;

}

// 编辑按钮状态
.editbtn {
  color: #2558e9;
  height: 36px;
  border: solid 1px #2558e9;

  .btn-category-icon path {
    fill: #2558E9;
  }

  &:hover,
  &:focus {
    background-color: #f3f7ff !important;
  }
}

.editbtn.ant-btn[disabled],
.editbtn.ant-btn[disabled]:hover,
.editbtn.ant-btn[disabled]:focus,
.editbtn.ant-btn[disabled]:active {
  color: #2558e9;
  border-color: #2558e9;
  background: #fff;
}


// ant 模态窗全局样式
.ant-modal-confirm-body .ant-modal-confirm-title {
  font-size: 16px;
  color: #24243a;
  font-weight: 600;
  margin-bottom: 20px;
}

// 函数对话框样式
.ant-modal-confirm .ant-modal-body {
  padding: 0px;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  padding: 20px 20px 10px;
  border-bottom: 1px solid #F0F0F0;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  padding: 0px 20px 0px;
  font-size: 14px;
  color: #24243a;
  font-weight: 400;
  font-family: PingFangSC, PingFangSC-Medium;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  display: flex;
  flex-direction: row-reverse;
  padding: 0px 20px 20px;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn+.ant-btn {
  margin-right: 20px;
}

// 接口请求加载loading样式
#loading {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(247, 252, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  font-size: 20px;
}

// 路由加载中样式
.bim_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  color: #9BA2AB;
  font-size: 14px;
  background-color: #f3f7ff;
}

// 解决苹果自带浏览器不能输入
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

*:not(input, textarea) {
  -webkit-touch-callout: none;
  // -webkit-user-select: none;
}

// 表格fixed阴影
.ant-table-ping-left .ant-table-cell-fix-left-first:after,
.ant-table-ping-left .ant-table-cell-fix-left-last:after,
.ant-table-ping-left:not(.ant-table-has-fix-left)>.ant-table-container:before {
  opacity: 0.5;
}

.ant-table-ping-right .ant-table-cell-fix-right-first:after,
.ant-table-ping-right .ant-table-cell-fix-right-last:after,
.ant-table-ping-right:not(.ant-table-has-fix-right)>.ant-table-container:after {
  opacity: 0.5;
}

.rc-virtual-list-scrollbar-thumb {
  background: #e5e5e5 !important;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child {
  background-color: #ffffff;
}

// 按钮前缀自定义图标样式
.ant-btn>.btn-custom-icon {
  vertical-align: middle;
  height: 20px;
  margin-right: 3px;
}

// 新建输入框样式
.inputrole {
  width: 100%;
  padding: 6px 16px;
  background: #f7f7f7;
  border-radius: 5px;
  border-color: #f7f7f7;

  .ant-input {
    background: #f7f7f7 !important;
    border-radius: 5px;
    font-size: 14px !important;
    line-height: 26px !important;
  }
}

// modal无上下边框
.modal-Nborder {
  .ant-modal-content .ant-modal-header {
    border-bottom: none;
    padding-bottom: 10px;
  }

  .ant-modal-content .ant-modal-footer {
    border-top: none;
  }
}

// modal无上边框
.modal-NTborder {
  .ant-modal-content .ant-modal-header {
    border-bottom: none;
    padding-bottom: 10px;
  }
}

// modal无下边框
.modal-NBborder {
  .ant-modal-content .ant-modal-footer {
    border-top: none;
  }
}

// 工序成本模块 下拉远程搜索样式
.quotaMenu .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: 13px 26px;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
}

// 按钮圆角
.ant-btn-round,
.ant-btn-round.ant-btn-sm,
.ant-modal-footer .ant-btn {
  border-radius: 6px;
}

// 搜索框圆角
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 6px 0 0 6px;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
  border-radius: 0 6px 6px 0;
}

// 气泡组件：无箭头、无边距
.popover-Narrow {
  padding-top: 0;

  .ant-popover-inner-content {
    padding: 0px;
  }

  .ant-popover-inner {
    border-radius: 8px;
  }

  // 隐藏箭头
  // .ant-popover-arrow {
  //   display: none;
  // }
}

// 解决下拉框后方偶尔出现‘A’的样式处理
.ant-select-multiple .ant-select-selector:after {
  content: ".";
  color: #ffffff;
}

// tabs未选中颜色设置
.ant-tabs-tab {
  color: #9BA2AB;
}

// 换行  tooltip
.ant-tooltip-inner {
  word-break: break-all;
  white-space: pre-line;
}

// 换行  div
.content_huan {
  white-space: pre-line;
}

.line_feed_one {
  word-wrap: break-word;
  /*强制换行*/
  overflow: hidden;
  /*超出隐藏*/
  text-overflow: ellipsis;
  /*隐藏后添加省略号*/
  white-space: nowrap;
}

.line_feed_two {
  display: -webkit-box;
  overflow: hidden;
  /*超出隐藏*/
  text-overflow: ellipsis;
  /*隐藏后添加省略号*/
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; //想显示多少行
}

// 去掉form表单自带的'：'
.ant-form-item-label>label:after {
  content: "";
}

// 隐藏form表单前的红色星号
.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  visibility: hidden;
}

// 在form表单后添加红色星号
.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

// antd 抽屉样式更改
.ant-drawer {
  .ant-drawer-title {
    font-weight: bold;
  }

  .ant-drawer-extra {
    cursor: pointer;
  }

  .ant-drawer-footer {
    display: flex;
    justify-content: flex-end;

    >button:last-child {
      margin-left: 20px;
    }
  }
}


// antd table拖拽排序样式
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

// .process_designer_img {
//   .djs-palette-entries {
//     display: none;
//   }
// }



.user_dropdown {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 13px 0;

  .ud_team {
    width: 190px;
    text-align: center;
    padding: 5px 20px 10px 20px;
    margin-bottom: 10px;
    color: #9ba2ab;
    border-bottom: 1px solid #f0f0f0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .ud_box {
    width: 170px;
  }

  .ud_item {
    display: flex;
    align-items: center;
    color: #24243a;
    margin: 0 10px;
    padding: 20px;
    text-decoration: none;
    height: 40px;
    border-radius: 6px;
    min-width: 120px;
    cursor: pointer;

    svg {
      width: 18px;
      height: 18px;
      vertical-align: middle;
    }

    >span:last-child {
      padding-left: 10px;
    }

    &:hover {
      background: #f3f7ff;

      svg {
        path {
          fill: #2558e9;
        }
      }

      span {
        color: #2558e9;
      }
    }
  }
}


// bpmn样式
#bpmn_canvas {
  background-image: linear-gradient(#F0F0F0 1px, transparent 0), linear-gradient(90deg, #F0F0F0 1px, transparent 0);
  background-size: 20px 20px;

  .highlight.djs-shape .djs-visual> :nth-child(1) {
    fill: green !important;
    stroke: green !important;
    fill-opacity: 0.2 !important;
  }

  .highlight.djs-shape .djs-visual> :nth-child(2) {
    fill: green !important;
  }

  .highlight.djs-shape .djs-visual>path {
    fill: green !important;
    fill-opacity: 0.2 !important;
    stroke: green !important;
  }

  .highlight.djs-connection>.djs-visual>path {
    stroke: green !important;
  }

  .highlight-todo.djs-connection>.djs-visual>path {
    stroke: orange !important;
    stroke-dasharray: 4px !important;
    fill-opacity: 0.2 !important;
  }

  .highlight-todo.djs-shape .djs-visual> :nth-child(1) {
    fill: orange !important;
    stroke: orange !important;
    stroke-dasharray: 4px !important;
    fill-opacity: 0.2 !important;
  }

  .highlight-reject.djs-connection>.djs-visual>path {
    stroke: red !important;
    stroke-dasharray: 4px !important;
    fill-opacity: 0.2 !important;
  }

  .highlight-reject.djs-shape .djs-visual> :nth-child(1) {
    fill: red !important;
    stroke: red !important;
    stroke-dasharray: 4px !important;
    fill-opacity: 0.2 !important;
  }
}


// 移动端
@media (max-width: 767px) {

  // 无数据样式
  .noData {
    img {
      width: 260px;
      height: 180px;
    }
  }
}