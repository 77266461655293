// 字体样式
$font-hei: -apple-system, "Noto Sans", "Helvetica Neue", Helvetica, "Nimbus Sans L", Arial, "Liberation Sans", "PingFang SC", "Hiragino Sans GB", "Noto Sans CJK SC", "Source Han Sans SC", "Source Han Sans CN", "Microsoft YaHei", "Wenquanyi Micro Hei", "WenQuanYi Zen Hei", "ST Heiti", SimHei, "WenQuanYi Zen Hei Sharp", sans-serif;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    scrollbar-color: #e5e5e5 #ffffff;
    /* 滑块颜色  滚动条背景颜色 */
    scrollbar-width: thin;
    /* 滚动条宽度有三种：thin、auto、none */
}

*::before {
    box-sizing: border-box;
}

*::after {
    box-sizing: border-box;
}

div,
p {
    margin: 0;
    padding: 0;
}

ul,
ol {
    list-style: none;
}

a {
    text-decoration: none;
    color: inherit;
}

button,
input {
    font: inherit;
}

:focus {
    outline: none;
}

// flex布局
.flex_justify_center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex_space_betwee {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex_end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flex_box {
    display: flex;
    align-items: center;
}

.flex_column {
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.flex_warp {
    display: flex;
    flex-flow: wrap;
}